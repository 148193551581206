import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'
import classNames from 'classnames'
import Img, { FluidObject } from 'gatsby-image'

import iconAndroid from 'images/service-icon/service-icon-android.svg'
import iconIOS from 'images/service-icon/service-icon-ios.svg'
import iconWeb from 'images/service-icon/service-icon-web.svg'

import Container from 'components/container'

const services = [
  {
    id: 'web',
    title: 'Build a website',
    description: 'Our process is built around quality-driven development',
    icon: iconWeb,
    imageName: 'service-hero-web.png',
  },
  {
    id: 'ios',
    title: 'IOS app',
    description:
      'We build native apps for iOS devices, into apps your customers can enjoy.',
    icon: iconIOS,
    imageName: 'service-hero-ios.png',
  },
  {
    id: 'android',
    title: 'Android app',
    description:
      'We customize and build Android app just for you and your users.',
    icon: iconAndroid,
    imageName: 'service-hero-android.png',
  },
  {
    id: 'digital-marketing',
    title: 'Digital marketing',
    description:
      'We help you to grow your business with our digital marketing services.',
    icon: iconWeb,
    imageName: 'service-hero-web.png',
  },
]

const Wrapper = styled.div`
  margin: 76px 0 100px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    margin: 116px 0 180px 0;
  }

  h1 {
    color: ${({ theme }) => theme.colors.blue300};
    padding-top: 24px;
    font-size: 30px;
    line-height: 1.34;
    font-weight: 800;
    margin-bottom: 64px;
    text-align: center;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      font-size: 48px;
      margin-bottom: 80px;
      text-align: left;
    }
  }

  .services {
    position: relative;
    max-width: ${({ theme }) => theme.breakpoints.xx};
    margin: auto;

    .service-list {
      width: 100%;

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        width: 50%;
      }
    }

    .service-image {
      display: none;
      position: absolute;
      left: 55%;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.3s ease-in-out;

      &#web {
        right: -141px;
      }

      &#ios {
        right: -37px;
      }

      &#android {
        right: -51px;
      }

      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        max-height: 80vh;
        object-fit: contain;
        object-position: 100% 50%;
      }

      @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        display: flex;
        align-items: center;
        transform: translateX(50px);
        opacity: 0;

        &.active {
          transform: translateX(0);
          opacity: 1;
        }
      }
    }
  }
`

const ServiceItemWrapper = styled.div`
  display: block;
  position: relative;
  border-radius: 16px;
  text-align: center;
  padding: 0 28px;
  margin-top: 56px;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: flex;
    align-items: center;
    text-align: left;
    padding: 32px 24px;
    margin-top: 16px;
    border: solid 4px transparent;
    background: white;
    background-clip: padding-box;
    border-radius: 1em;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      margin: -4px;
      border-radius: inherit;
      background: linear-gradient(to right, #356bfa, #5a3cce);
      opacity: 0;
      transition: 0.3s ease-in-out;
      transition-property: all;
    }

    &.active {
      box-shadow: 0px 2px 20px rgba(85, 164, 243, 0.5);

      &:before {
        opacity: 1;
      }
    }
  }

  & > div {
    min-width: 0;
  }

  .icon-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.blue50};
    margin: 0 auto 30px auto;

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
      margin: 0;
      margin-right: 24px;
    }
  }

  .content {
    flex: 1;

    .title {
      margin-bottom: 8px;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.blue200};
      font-size: ${({ theme }) => theme.text.p2.fontSize};
      font-weight: 800;
    }

    .desc {
      color: ${({ theme }) => theme.colors.grey400};
      font-size: ${({ theme }) => theme.text.p1.fontSize};
    }
  }
`

interface ServiceItemProps {
  title: string
  description: string
  active: boolean
  id: string
  icon: any
}

const ServiceItem: React.FC<
  ServiceItemProps & React.HTMLProps<HTMLDivElement>
> = ({ title, description, icon, id, active, onMouseOver }) => {
  return (
    <ServiceItemWrapper
      id={id}
      className={classNames({ active })}
      onMouseOver={onMouseOver}
    >
      <div className="icon-wrapper">
        <img src={icon} alt={id} />
      </div>
      <div className="content">
        <div className="title">{title}</div>
        <div className="desc">{description}</div>
      </div>
    </ServiceItemWrapper>
  )
}

interface ServiceImageProps {
  active: boolean
  name: string
  data: { childImageSharp: { fluid: FluidObject } }
}

const ServiceImage: React.FC<ServiceImageProps> = ({ active, data, name }) => {
  return (
    <div className={classNames('service-image', { active })} id={name}>
      <Img
        fluid={data.childImageSharp.fluid}
        alt={name}
        imgStyle={{ objectFit: 'contain', objectPosition: '100% 50%' }}
      />
    </div>
  )
}

const Services: React.FC = () => {
  const [count, setCount] = useState(0)
  const [isHovering, setIsHovering] = useState(false)
  const activeItem = count % services.length

  useEffect(() => {
    const t = setInterval(() => {
      if (!isHovering) {
        setCount((c) => c + 1)
      }
    }, 3000)
    return () => clearInterval(t)
  }, [isHovering])

  const data = useStaticQuery(graphql`
    query {
      serviceImages: allFile(
        filter: { relativeDirectory: { eq: "service-hero" } }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fluid {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { serviceImages } = data

  return (
    <Wrapper id="services" className="jumptarget">
      <Container>
        <h1>Our services</h1>
      </Container>
      <div className="services">
        <Container>
          <div
            className="service-list"
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            {services.map((s, i) => (
              <ServiceItem
                key={s.id}
                {...s}
                active={i === activeItem}
                onMouseOver={() => setCount(i)}
              />
            ))}
          </div>
        </Container>
        {serviceImages?.edges?.map((item) => {
          const name = item?.node?.childImageSharp?.fluid?.originalName
          const value = services.findIndex((s) => s.imageName === name)
          return (
            <ServiceImage
              key={name}
              name={services[value].id}
              data={item.node}
              active={activeItem === value}
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export default Services
